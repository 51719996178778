import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

import history from '@/history';
import { getUserPermsSync } from '@/rbac';
import actions from '@routes/actions.json';

import s from './Base.scss';

const Base = props => {
  const { auth, children, redirect } = props;
  const { isAuthenticated, account } = auth;

  const hasSwitchedContext =
    account?.context?.accountname !== account.accountname;

  // Obtain the account context in order to qualify any `history` changes
  // required, either within this view or by any subcomponents within this
  // view
  const context = account.context?.accountname
    ? `/${encodeURIComponent(account.context?.accountname)}`
    : '';

  useEffect(() => {
    if (isAuthenticated) {
      const primaryPerms = getUserPermsSync({
        account,
        actions: actions[redirect.primary.path],
      });

      const secondaryPerms = getUserPermsSync({
        account,
        actions: actions[redirect.secondary.path],
      });

      history.replace(
        primaryPerms && redirect.primary.perms.every(perm => primaryPerms[perm])
          ? `${context}/${redirect.primary.path}`
          : secondaryPerms &&
              redirect.secondary.perms.every(perm => secondaryPerms[perm])
            ? `${context}/${redirect.secondary.path}`
            : `${context}/${redirect.tertiary.path}`,
        {},
      );
    }
  }, [isAuthenticated, hasSwitchedContext]);

  return (
    <div className={s.root}>
      {auth && auth.isAuthenticated ? (
        <Dimmer active className={s.pageDimmer}>
          <Loader size="huge" active inline="centered" content="Loading" />
        </Dimmer>
      ) : (
        children
      )}
    </div>
  );
};

export const propTypes = {
  children: PropTypes.node.isRequired,
  redirect: PropTypes.shape({
    primary: PropTypes.shape({
      path: PropTypes.string,
      perms: PropTypes.arrayOf(PropTypes.string),
    }),
    secondary: PropTypes.shape({
      path: PropTypes.string,
      perms: PropTypes.arrayOf(PropTypes.string),
    }),
    tertiary: PropTypes.shape({
      path: PropTypes.string,
      perms: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
  auth: PropTypes.shape({
    prevAuthState: PropTypes.bool.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    account: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        accountname: PropTypes.string,
        isActive: PropTypes.bool,
        isAdmin: PropTypes.bool,
        sessionID: PropTypes.string,
        sessionStart: PropTypes.string,
        userCreatedAt: PropTypes.string,
        userCreatedBy: PropTypes.string,
        userLastUpdated: PropTypes.string,
        username: PropTypes.string,
      }),
    ]),
  }).isRequired,
};

Base.propTypes = propTypes;

export default withStyles(s)(Base);
